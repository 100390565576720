import { Controller } from '@hotwired/stimulus';
import { debounce } from '@yobbers/js/utils/dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useHotkeys } from 'stimulus-use/hotkeys';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['wrapper', 'input', 'form', 'scroll', 'frame'];
  static values = {
    url: String
  };

  connect() {
    if (!this.hasWrapperTarget) return;

    this.backgroundElement = this.element.querySelector('.c-site-search__bg');

    this.inputTarget.addEventListener('input', debounce(this.submit, 400));
    this.formTarget.addEventListener('turbo:submit-start', this.showLoader);
    this.formTarget.addEventListener('turbo:submit-end', this.hideLoader);
  }

  visit = () => {
    let url = new URL(this.urlValue);
    url.searchParams.append('search_term', this.inputTarget.value);
    Turbo.visit(url);
  };

  clearInput() {
    this.inputTarget.value = '';
    this.inputTarget.focus();
  }

  showLoader = (e) => {
    this.wrapperTarget.classList.add('-is-loading');
  };

  hideLoader = (e) => {
    this.wrapperTarget.classList.remove('-is-loading');
  };

  toggle() {
    this.wrapperTarget.classList.contains('-is-active')
      ? this.close()
      : this.open();
  }

  open() {
    this.wrapperTarget.classList.add('-is-active');
    disableBodyScroll(this.scrollTarget);
    this.inputTarget.focus();

    this.enableHotkeys();

    this.wrapperTarget.addEventListener('click', this._handleClickOutside);
  }

  enableHotkeys = () => {
    this.hotkeys = useHotkeys(this, {
      hotkeys: {
        esc: {
          handler: this.close
        },
        enter: {
          handler: this.visit,
          element: this.inputTarget
        }
      },
      filter: () => true
    });
  };

  close = () => {
    this.wrapperTarget.classList.remove('-is-active');
    this.inputTarget.blur();
    clearAllBodyScrollLocks();
    this.hotkeys.unbind();

    this.wrapperTarget.removeEventListener('click', this._handleClickOutside);
  };

  submit = (e) => {
    e.preventDefault();
    this.formTarget.requestSubmit();
  };

  _handleClickOutside = (event) => {
    if (event.target === this.backgroundElement) this.close();
  };
}
